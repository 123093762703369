const SETTINGS_PATH = 'settings';

/**
 * List of platform app objects that exist at a specific path.
 *
 * Apps that do not appear at a specific path (header, footer, utility apps like
 * auth-util, etc) are not included in this list because they do not appear in
 * the single-spa router (by definition) and therefore do not have custom props
 * passed to them in the aiops-root-config.ts file.
 *
 * None of these app objects need a group property because they do not
 * have a group and should not appear as choices in the app picker app.
 */
const platformApps = [
    {
        appId: 'APP_PICKER',
        appName: 'App Picker',
        appPackageName: '@aiops/app-picker',
        path: '/',
        exactPath: true,
    },
    {
        appId: 'AUTH_REDIRECT',
        appName: 'Auth Redirect',
        appPackageName: '@aiops/auth-redirect',
        path: 'auth',
    },
    {
        appId: 'SETTINGS',
        appName: 'Settings',
        appPackageName: '@aiops/settings',
        path: SETTINGS_PATH,
    },
].map((app) => ({
    ...app,
    platform: true,
    hidden: true,
}));

// List of app objects.
const apps = [
    {
        appId: 'S2PIM',
        appName: 'AP Invoice Management',
        appPackageName: '@aiops/invoice-management',
        path: 'ap-invoice-management',
        group: 'Autonomous Source to Pay',
        endpoints: {},
    },
    {
        appId: 'BaaSSR',
        appName: 'Contract Renewals',
        appPackageName: '@aiops/renewal-mgmt',
        path: 'contract-renewals',
        group: 'Autonomous Billing',
        endpoints: {
            getSubscriptionsUrl: process.env.BAASSR_ENDPOINT_GET_SUBSCRIPTIONS,
            getSmartQuoteUrl: process.env.BAASSR_ENDPOINT_GET_SMART_QUOTE,
            sendEmailsUrl: process.env.BAASSR_ENDPOINT_POST_SEND_EMAIL,
            adminConfirmationUrl: process.env.BAASSR_ENDPOINT_POST_ADMIN_CONFIRMATION,
        },
    },
    {
        appId: 'BaaSSR_ACTIONS',
        appName: 'Contract Renewals Actions',
        appPackageName: '@aiops/actions',
        path: 'actions',
        hidden: true,
        endpoints: {
            postCustomerFlowUrl: process.env.BAASSR_ACTIONS_ENDPOINT_POST_CUSTOMER_FLOW,
        },
    },
    {
        appId: 'FinCA',
        appName: 'Accounts Receivable Cash App',
        appPackageName: '@aiops/cash-app',
        path: 'cash-app',
        group: 'Autonomous Finance',
        endpoints: {
            getInvoicesUrl: process.env.FINCA_ENDPOINT_GET_INVOICES,
            postApplySmartMatchUrl: process.env.FINCA_ENDPOINT_POST_APPLY_SMART_MATCH,
            putRejectSmartMatchUrl: process.env.FINCA_ENDPOINT_PUT_REJECT_SMART_MATCH
        },
    },
    {
        appId: 'DaaSADM',
        appName: 'Autonomous Data Management',
        appPackageName: '@aiops/master-data-management',
        path: 'autonomous-data-management',
        group: 'Autonomous Data Operations',
        endpoints: {
            admRootUrl: process.env.ADM_ENDPOINT_BASE_PATH,
            apiHUBRootUrl: process.env.DAAS_ENPOINT_BASE_PATH,
        },
        hasSettings: true,
    },
    {
        appId: 'S2PRW',
        appName: 'Procurement Execution',
        appPackageName: '@aiops/procurement-execution',
        path: 'procurement-execution',
        group: 'Autonomous Source to Pay',
        endpoints: {
            KMSEndpointBaseUrl: process.env.KMS_ENDPOINT_BASE_URL,
        }
    },
    {
        appId: 'S2PCat',
        appName: 'Category Management',
        appPackageName: '@aiops/category-management',
        path: 'category-management',
        group: 'Autonomous Source to Pay',
        endpoints: {}
    },
];

/**
 * Combination of platform apps and this configuration's apps.
 */
export const appList = [
    ...platformApps,
    ...apps,
].map((app) => {

    // Make sure app object doesn't have conflicting hidden and grouping values;
    // if okay, explicitly set both.
    if ((app.hidden === false && !app.group) || (app.hidden && app.group)) {
        throw new Error(`${app.appName} app object has hidden value ("${app.hidden}") and group value ("${app.group}") that conflict.)`)
    }
    const group = app.group || null;
    const hidden = app.hidden || !app.group;

    return ({
        ...app,
        endpoints: {
            ...app.endpoints,
            endpointBaseUrl: process.env.ENDPOINT_BASE_URL,
        },
        group,
        hidden,
        settingsPath: SETTINGS_PATH,
        hasSettings: !!app.hasSettings,
        access: true,
    });
});
