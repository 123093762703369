import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { appList } from './appList';

// This template (minus the mapping function) is adapted from the html/string in
// the microfrontend-layout.html file automatically created by create-single-spa.
// Using the mapping function here instead of the html file means that the app
// routing can be configured from the appList file (ie, a single source of truth
// for the apps in this configuration, and their names, and paths, etc).
const stringLayout = `
<single-spa-router>
<div id="root-nav-wrap" class="col">
  <application name="@aiops/header"></application>
</div>
<main>
  ${appList.map((app) => {
  if (!app.path) {
    return null;
  }
  return `
    <route path=${app.path}${app.exactPath ? " exact=true" : ""}>
    <application name="${app.appPackageName}"></application>
  </route>
    `
}).filter((hasPath) => hasPath)
    .join(' ')
  }

</main>
<div id="root-footer-wrap" class="col">
  <application name="@aiops/footer"></application>
</div>
</single-spa-router>
`

const routes = constructRoutes(stringLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Pass information about the app (ie its path and id) to the app
applications.forEach((singleSpaApp => {
  // singleSpaApp is the application object created by single spa
  // appListApp is the application object in the appList
  const appListAppObj = appList.find(a => a.appPackageName === singleSpaApp.name) || {}
  registerApplication({
    ...singleSpaApp,
    customProps: {
      app: {
        ...appListAppObj,
      },
    },
  });
}));

// Import styleguide before activating the layout engine so that the platform-
// wide css is loaded before any of the apps that depend on it for layout.
System.import("@aiops/styleguide").then(() => {
  layoutEngine.activate();
  start();
});

export const getConfig = () => {
  return ({
    appList,
    auth: {
      clientId: process.env.AUTH_CLIENT_ID,
      redirectUrl: process.env.AUTH_REDIRECT_URL,
      userPoolId: process.env.AUTH_USER_POOL_ID,
      region: process.env.AUTH_REGION,
      realm: process.env.KEYCLOAK_REALM,
      endpoints: {
        authorize: process.env.AUTH_ENDPOINT_AUTHORIZE,
        tokenFromAuthCode: process.env.AUTH_ENDPOINT_TOKEN_FROM_AUTH_CODE,
        revokeToken: process.env.AUTH_ENDPOINT_REVOKE_TOKEN,
        logout: process.env.AUTH_ENDPOINT_LOGOUT,
        checkPermissions: process.env.AUTH_ENDPOINT_CHECK_PERMISSIONS,
      },
    },
    endpointBaseUrl: process.env.ENDPOINT_BASE_URL,
    eventStreamBaseUrl: process.env.EVENT_STREAM_BASE_URL,
    authMethod: process.env.AUTH_METHOD,
    footer: {
      endpoints: {
        getDocument: process.env.FOOTER_ENDPOINT_GET_DOCUMENT,
      },
    },
  });
}